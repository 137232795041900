<template>
  <div>

    hello success
    {{ query}}
  </div>

</template>
<script>
import router from '@/router';

export default {
  props: ['query'],
  mounted() {
    router.push(`/agenda?success=${true}`);
  },
};

</script>
